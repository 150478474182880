import React, {useEffect, useState} from "react";

import CloseSvg from "../../svg/close.js";

//redux
import {connect} from "react-redux";

//component
import FormPop from "./form-pop-activite.js";

//service
import Translator from "../../translator";
import {getCategorieDureePeriodeMinimum} from "../../../server/services/unite-service";
import {getTypePeriode} from "../../../utils/activite/get-type-periode";
import loadingImage from "../../../../images/loading.svg";

const ModalActivite = ({
                           prix, onClose, onClickAjouterPanier, nbreDisponible, minDate, date, typePeriode, calendarModal, _setcalendarModal, _setDate, dureePeriode, idUniteCategorie, isDataLoading
                       }) => {
    const [texts, setTexts] = useState(Translator('fr', "TXT_UNITE_FORM_ACTIVITE"))
    const [dureeMinimum, setDureeMinimum] = useState();

    useEffect(() => {
        getCategorieDureePeriodeMinimum(idUniteCategorie, date).then(result => {
            setDureeMinimum(result);
        });
    }, []);

    return (<div className="overlay">

        <div className="modal-pop-form content-pop-activite">
            { isDataLoading && <div style={ {
                backgroundColor: "rgba(0, 0, 0, 0.19)",
                zIndex: 99999,
                position: "absolute",
                display: "flex",
                backdropFilter: "blur(2px)",
                height: "100%",
                width: "100%",
                flexWrap: "nowrap",
                padding: "0px",
                top: "0px",
                left: "0px",
                flex: 1,
                alignItems: "center"
            } }>
                <img src={ loadingImage } alt="chargement" style={ {flex: 1} }/>
            </div> }
            <div className="content-modal">
                {/* header */ }
                <div className="header-pop-active">
                    <span className="title-pop-active">reservation</span>
                    <CloseSvg fill="white" onClick={ () => onClose() } className=" closeButton-activite" svgClassName="override-default" width={ 36 } height={ 36 } style={ {float: "right"} }/>
                </div>

                {/*Body  */ }
                <div className="body-pop-activte">
                    <FormPop
                        prix={ prix }
                        date={ date }
                        minDate={ minDate }
                        typePeriode={ typePeriode }
                        dureePeriode={ dureePeriode }
                        dureeMinimum={ dureeMinimum?.dureeMinimum }
                        calendarModal={ calendarModal }
                        _setcalendarModal={ (value) => _setcalendarModal(value) }
                        _setDate={ (dateSelect) => _setDate(dateSelect) }
                    />
                    <div style={ {display: "flex", justifyContent: "space-between", alignItems: "center"} }>
                        <div className={ `nbre-activite ${ nbreDisponible > 0 ? "nbre-activite-disponible" : "nbre-activite-non-disponible" }` }>
                                <span style={ {color: `${ nbreDisponible > 0 ? "#000" : "#fff" }`} }>
                                    { nbreDisponible } Disponible{ nbreDisponible > 1 ? 's' : '' }
                                </span>
                        </div>
                        <div style={ {justifyItems: "center", paddingRight: "10px"} }>
                            { dureeMinimum?.dureeMinimum && dureeMinimum?.dureeMinimum !== dureeMinimum?.dureePeriode ? <span style={ {color: `red`, fontSize: "1.2em"} }>
                                <b>{ dureeMinimum.dureeMinimum } { getTypePeriode(dureeMinimum?.typePeriode, dureeMinimum?.dureeMinimum) } minimum</b>
                                </span> : null }
                        </div>
                    </div>
                </div>
                {/* Footer */ }
                <div className="footer-pop-active">
                    <div className="droit-acces-page btn-span-wrapper content-btn-pop">
                        <div className="btn-pop-active" style={ {alignItems: "center", paddingTop: "1.5rem"} } onClick={ () => onClose() }>
                            <span>Fermer</span>
                        </div>
                        <div className="btn-pop-active" onClick={ () => {
                            onClickAjouterPanier();
                            onClose()
                        } }>
                            <span>{ texts.title0 } </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

const mapStateToProps = (state) => ({
    idUniteCategorie: state.formPopActive.idUniteCategorie,
});

export default connect(mapStateToProps, {})(ModalActivite);