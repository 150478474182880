import {formatDateYMD} from "../formatting.js";
import {CartItemType} from "../../components/actions/shop.js";
import {getTrancheAge} from '../../server/services/trancheage.js';
import {addReservationTemp, calculateSumarryAmounts} from "../../server/services/reservation-service.js";
import {addDays} from "date-fns";

const constNewItem = {
    quantite: "Quantite",
    date: "Date",
    debut: "Debut",
    fin: "Fin"
}

/**
 La fonction `addpanier` est utilisée pour gérer l'ajout d'une activite au panier. Elle prend plusieurs paramètres nécessaires pour effectuer cette opération, notamment les informations sur l'activite à ajouter, telles que le nom de l'activité, l'identifiant de la catégorie d'unité, le montant, la quantité disponible, et la date sélectionnée.
 Elle assure également la gestion des alertes en cas de conditions spécifiques non respectées lors de l'ajout, telles que des heures de début et de fin vides ou identiques, ou encore une quantité demandée supérieure à la quantité disponible.
 Résumé des principales étapes:

 1. Vérification des heures de début et de fin : La fonction vérifie si les heures de début et de fin sont renseignées et qu'elles ne sont pas identiques. Si ces conditions ne sont pas respectées, elle affiche une alerte appropriée.

 2. Création de l'élément à ajouter au panier : Elle crée un nouvel objet représentant l'activite à ajouter, en incluant des informations telles que la date, l'heure de début et l'heure de fin.

 3. Gestion des éléments existants dans le panier : La fonction filtre les éléments déjà présents dans le panier pour vérifier s'il existe des articles avec le même identifiant de catégorie d'unité. Si c'est le cas, elle vérifie également s'il existe des éléments avec la même date que celle sélectionnée pour l'activite à ajouter.

 4. Ajout de l'activite au panier : En fonction des vérifications effectuées, la fonction ajoute l'article au panier avec la quantité spécifiée, en tenant compte des conditions telles que la quantité disponible et les articles déjà présents dans le panier avec des caractéristiques similaires.

 5. Gestion des alertes : Si une condition spécifique n'est pas respectée lors de l'ajout au panier, la fonction affiche une alerte indiquant à l'utilisateur la raison pour laquelle l'ajout n'a pas pu être effectué.

 * @param {*} idUnite L'id de l'unite selectionne
 * @param {*} prix Le prix de l'activité à ajouter au panier
 * @param {*} dateState La date de l'activité à ajouter au panier
 * @param {*} nameActivite Le nom de l'activité à ajouter au panier.
 * @param {*} props Les propriétés utilisées pour ajouter l'élément au panier
 * @param {*} nbreDisponible La quantité disponible de l'activité à ajouter au panier.
 * @returns {void} la fonction ne retourne rien
 */
export const addpanier = async (props, nameActivite, prix, dateState, typePeriode, uniteArray) => {
    const {heureDebut, heureFin, setAlert, qte, idUniteCategorie, idTypeUnite, addToShoppingCart} = props;
    if (heureDebut === '' || heureFin === '') {
        setAlert(`Vous devez sélectionner les heures`, 'danger');
        return;
    }

    if (heureDebut === heureFin) {
        setAlert(`Les heures de début et de fin sont identiques`, 'danger');
        return;
    }

    if (uniteArray.length > 0) {
        try {
            for (let i = 0; i < qte; i++) {
                const newItem = await createNewItem(nameActivite, prix, 1, uniteArray[i].idUnite, idTypeUnite, idUniteCategorie, dateState, typePeriode, heureDebut || '00:00', heureFin || '23:59', setAlert);

                var montants = await calculateSumarryAmounts(newItem["selectedUnite"]);
                if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) {
                    // this.setState((prevState) => ({
                    //     ...prevState,
                    //     isOkverification: false
                    // }));
                    setAlert("Une erreur c'est produite lors de l'ajout au panier", 'danger');
                    return;
                }

                const result = await addReservationTemp({
                    dateDebut: `${ formatDateYMD(dateState) }T${ heureDebut || '00:00' }:00Z`,
                    dateFin: `${ formatDateYMD(addDays(dateState, typePeriode === 2 ? 1 : 0)) }T${ heureFin || '23:59' }:00Z`,
                    idUnite: uniteArray[i].idUnite
                });

                newItem["id"] = result.idsReservationTemp[0];
                newItem["idsReservationTemp"] = result.idsReservationTemp;
                addToShoppingCart(newItem);
            }
        } catch (error) {
            console.log('erreur', error)
        }

    } else {
        setAlert(`La quantité à ajouter dépasse la quantité totale disponible qui est de ${ uniteArray.length } pour cette unité`, 'danger');

    }

};

const createNewItem = async (nameActivite, prix, qteToAdd, idUnite, idTypeUnite, idUniteCategorie, dateState, typePeriode, heureDebut, heureFin, setAlert) => {

    try {

        const adulte = await getTrancheAge();
        const idTrancheDageAdulte = adulte.find(tranche => tranche.isAdulte);

        if (adulte) {
            return {
                name: nameActivite,
                type: CartItemType.ReservationActivite,
                montant: prix,
                isReservation: true,
                selectedUnite: {
                    selectedTrancheAges: [{IdTrancheAge: idTrancheDageAdulte.idTrancheAge, quantite: qteToAdd}],
                    type_hebergement: 'AC',
                    id_unite: idUnite,
                    id_type_unite: idTypeUnite,
                    id_unite_categorie: idUniteCategorie,
                    selectedDepart: `${ formatDateYMD(addDays(dateState, typePeriode === 2 ? 1 : 0)) }T${ heureFin }:00Z`,
                    selectedArrivee: `${ formatDateYMD(dateState) }T${ heureDebut }:00Z`,
                },
                inclus: [
                    {name: constNewItem.quantite, textVal: qteToAdd, value: qteToAdd},
                    {name: constNewItem.date, textVal: formatDateYMD(dateState), value: formatDateYMD(dateState)},
                    {name: constNewItem.debut, textVal: heureDebut, value: heureDebut},
                    {name: constNewItem.fin, textVal: heureFin, value: heureFin}
                ]
            };
        } else {
            setAlert(`Une erreur s'est produite lors de l'ajout au panier`, 'danger');
        }
    } catch (error) {
        console.log('une erreur lors de la creation de newItm pour ajour panier dans activite', error)
    }
};
